<template>
  <div
    class="user-tab-overview"
  >
    <v-card class="mb-7">
      <v-card-title>
        <p class="mb-2 mb-sm-0">
          User Details
        </p>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="editBioDialog = !editBioDialog"
        >
          Edit
        </v-btn>
      </v-card-title>

      <v-card-text>
        <user-details :is-table="true"></user-details>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-7"
      :loading="loading"
    >
      <v-card-title>Need higher limits? Refer a Friend</v-card-title>
      <v-card-text>All my toools are 100% free. I don't offer any paid upgrade, but I do enforce limits to prevent absuses.</v-card-text>
      <v-card-text>Need higher limits? Refer a friend using the link below for every 5 users who signs up using your link, I'll increase your limits by 20%</v-card-text>
      <v-card-text class="d-flex ">
        <v-text-field
          v-model="refferalCode"
          outlined
          placeholder="Referal link with copy button?"
          :prepend-inner-icon="icons.mdiWeb"
          :append-icon="icons.mdiContentCopy"
          readonly
          @click:append="copyToClipBoard"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        You can send traffic to any page of my blog or to <a
          href="https://app.divbyzero.com/register"
          target="_blank"
        >register page</a> as long as you keep the <code>?ref={{ authUser.referral_code }}</code> in the url
      </v-card-text>
      <v-card-text>So far you have referred: {{ refferedUsers.length }} Users</v-card-text>
      <v-card-text v-if="refferedUsers.length">
        <v-simple-table class="users__table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                Here are the last users you referred:
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in refferedUsers"
                :key="item.email"
              >
                <td>{{ item.email }}</td>
                <td>
                  {{ moment(item.created_at).fromNow() }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <!-- edit profile dialog data -->
    <user-bio-edit
      :edit-bio-dialog.sync="editBioDialog"
    ></user-bio-edit>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { mdiWeb, mdiContentCopy } from '@mdi/js'
import UserDetails from '../components/UserDetails.vue'
import UserBioEdit from '../user-bio-panel/UserBioEdit.vue'

import ApiClient from '@/services'

export default {
  components: { UserDetails, UserBioEdit },
  setup(props, { root: { $store } }) {
    const loading = ref(false)
    const authUser = computed(() => $store.state.auth.user)
    const refferalCode = ref(`https://divbyzero.com/tools/?ref=${authUser.value.referral_code}`)
    const refferedUsers = ref([])
    const editBioDialog = ref(false)
    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    const copyToClipBoard = () => {
      navigator.clipboard.writeText(refferalCode.value)
      $store.dispatch('notify', 'Copied to clipboard')
    }

    const getReferredUsers = () => {
      loading.value = true
      ApiClient.getReferredUsers()
        .then(response => {
          refferedUsers.value = response.data.data.users
        })
        .catch(err => {
          console.log(err)
          $store.dispatch('notify', err.response.data.message)
        })
        .finally(() => {
          loading.value = false
        })
    }

    getReferredUsers()

    return {
      loading,
      resolveUserProgressVariant,
      editBioDialog,
      authUser,
      refferalCode,
      refferedUsers,
      icons: {
        mdiWeb,
        mdiContentCopy,
      },
      copyToClipBoard,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}

.users__table {
  border-collapse: collapse;
  tr {
    td {
      border: thin solid rgba(94, 86, 105, 0.14);
    }
  }
}
</style>
