<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card
      class="mb-7"
      :loading="loading"
    >
      <v-card-title>
        Change Password
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
          text
        >
          <p class="font-weight-semibold mb-1">
            Ensure that these requirements are met
          </p>
          <p class="text-sm mb-0">
            Minimum 8 characters long
          </p>
        </v-alert>

        <!-- form -->
        <v-form
          ref="formRef"
          class="multi-col-validation"
          @submit.prevent="handleFormSubmit"
        >
          <v-alert
            v-if="requestError"
            color="error"
            text
            dark
            class="my-4"
          >
            {{ requestError }}
          </v-alert>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                :error-messages="errorMessages.password"
                placeholder="Password"
                :rules="[validators.required, validators.minLengthValidator]"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="confirmPassword"
                outlined
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                label="Confirm New Password"
                :error-messages="errorMessages.password"
                placeholder="Password"
                :rules="[validators.required, validators.minLengthValidator,validators.confirmedValidator(confirmPassword,newPassword)]"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                type="submit"
              >
                Change Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- two step verification -->
    <v-card class="mb-7">
      <v-card-title>
        Two-step verification
      </v-card-title>
      <v-card-subtitle>
        Keep your account secure with authentication step.
      </v-card-subtitle>

      <v-card-text>
        <div>
          <h4 class="font-weight-medium mb-1">
            SMS
          </h4>

          <h1 class="text-center">
            Coming Soon
          </h1>
          <!-- <div
            class="d-flex align-center justify-space-between "
          >
            <span class="user-verification-number">Coming Soon</span>
            <div>
              <v-btn
                icon
                small
                color="secondary"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                <v-icon size="20">
                  {{ icons.mdiSquareEditOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                small
              >
                <v-icon size="20">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>

        <v-divider></v-divider>

        <p class="mb-0 mt-4">
          Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in. <a
            href="javascript:void(0)"
            class="text-decoration-none"
          >Learn more</a>.
        </p>
      </v-card-text>
    </v-card>

    <!-- dialog -->
    <v-dialog
      v-model="istwoFactorDialogOpen"
      max-width="650px"
    >
      <v-card class="two-factor-dialog pa-sm-10">
        <v-card-title class="text-sm-h5 justify-center">
          Enable One Time Password
        </v-card-title>
        <v-card-text>
          <h4 class="text-lg font-weight-medium mb-2">
            Verify Your Mobile Number for SMS
          </h4>
          <p>Enter your mobile phone number with country code and we will send you a verification code.</p>

          <v-form>
            <v-text-field
              v-model="smsVerificationNumber"
              outlined
              dense
              placeholder="Mobile number with country code"
              label="Mobile number with country code"
            ></v-text-field>

            <div class="d-flex align-center">
              <v-btn
                color="secondary"
                outlined
                class="me-3 ms-auto"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                send
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { required, minLengthValidator, confirmedValidator } from '@core/utils/validation'
import apiClient from '@/services'

export default {
  setup(props, { root: { $store } }) {
    const istwoFactorDialogOpen = ref(false)
    const newPassword = ref('')
    const confirmPassword = ref('')
    const smsVerificationNumber = ref('')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    const formRef = ref(null)
    const loading = ref(false)
    const errorMessages = ref([])
    const requestError = ref('')

    // on form submit
    const handleFormSubmit = () => {
      const isFormValid = formRef.value.validate()

      if (!isFormValid) return

      loading.value = true
      apiClient
        .updatePassword({ password: newPassword.value, c_password: confirmPassword.value })
        .then(response => {
          requestError.value = response.data.message
        })
        .catch(err => {
          requestError.value = err.response.data.error || err.response.data.message
          errorMessages.value = err.response.data.errors
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      istwoFactorDialogOpen,
      newPassword,
      confirmPassword,
      smsVerificationNumber,
      isPasswordVisible,
      isPasswordConfirmVisible,

      formRef,
      errorMessages,
      requestError,
      loading,
      handleFormSubmit,

      validators: {
        required,
        minLengthValidator,
        confirmedValidator,
      },

      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
