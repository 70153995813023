<template>
  <v-row
    v-if="isTable"
    class="user-tab-billings-plans"
  >
    <v-col
      cols="12"
      lg="6"
    >
      <table class="billing-address-table">
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Full Name:
            </p>
          </td>
          <td><p>{{ authUser.name }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Email:
            </p>
          </td>
          <td><p>{{ authUser.email }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Country:
            </p>
          </td>
          <td><p>{{ __countryName(authUser.country_code) }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Website:
            </p>
          </td>
          <td><p>{{ authUser.website }}</p></td>
        </tr>
      </table>
    </v-col>

    <v-col
      cols="12"
      lg="6"
    >
      <table class="billing-address-table">
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Company Name:
            </p>
          </td>
          <td><p>{{ formatUserAttribute(authUser.company_name) }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Company Type:
            </p>
          </td>
          <td><p>{{ formatUserAttribute(authUser.company_type) }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Position:
            </p>
          </td>
          <td><p>{{ formatUserAttribute(authUser.position) }}</p></td>
        </tr>
        <tr>
          <td class="billing-title">
            <p class="text-no-wrap text--primary font-weight-medium">
              Department:
            </p>
          </td>
          <td><p>{{ formatUserAttribute(authUser.department) }}</p></td>
        </tr>
      </table>
    </v-col>
  </v-row>
  <v-list v-else>
    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium me-2">Full Name:</span>
      <span class="text--secondary">{{ authUser.name }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Email:</span>
      <span class="text--secondary">{{ authUser.email }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Country:</span>
      <span class="text--secondary">{{ __countryName(authUser.country_code) }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Website:</span>
      <span class="text--secondary">{{ authUser.website }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Company Name:</span>
      <span class="text--secondary">{{ formatUserAttribute(authUser.company_name) }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Company Type:</span>
      <span class="text--secondary">{{ formatUserAttribute(authUser.company_type) }}</span>
    </v-list-item>

    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Position:</span>
      <span class="text--secondary">{{ formatUserAttribute(authUser.position) }}</span>
    </v-list-item>
    <v-list-item
      dense
      class="px-0 mb-n2"
    >
      <span class="font-weight-medium text-no-wrap me-2"> Department:</span>
      <span class="text--secondary">{{ formatUserAttribute(authUser.department) }}</span>
    </v-list-item>
  </v-list>
</template>

<script>
import { computed } from '@vue/composition-api'

import userDB from '@/@fake-db/data/userDB'

export default {
  props: {
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root: { $store } }) {
    const authUser = computed(() => $store.state.auth.user)

    const formatUserAttribute = value => {
      if (userDB.companyTypes.find(item => item.value === value)) {
        return userDB.companyTypes.find(item => item.value === value).text
      }
      if (userDB.departments.find(item => item.value === value)) {
        return userDB.departments.find(item => item.value === value).text
      }
      if (userDB.positions.find(item => item.value === value)) {
        return userDB.positions.find(item => item.value === value).text
      }

      return value
    }

    return {
      authUser,
      formatUserAttribute,
    }
  },
}
</script>
