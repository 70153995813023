<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-hover v-slot="{ hover }">
            <v-avatar
              :color="authUser.profile_photo_path ? '' : 'primary'"
              :class="authUser.profile_photo_path ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4 cursor-pointer"
              @click="$refs.refAvatarInput.click()"
            >
              <v-img
                v-if="authUser.profile_photo_path"
                :src="__serverImage(authUser.profile_photo_path)"
              ></v-img>
              <span
                v-else
                class="font-weight-semibold text-5xl"
              >{{ avatarText(authUser.name) }}</span>

              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out   v-card--reveal "
                  style="height: 100%;"
                >
                  <v-icon
                    size="44"
                    class="icon"
                    elevation="4"
                  >
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                </div>
              </v-expand-transition>
            </v-avatar>
          </v-hover>

          <input
            ref="refAvatarInput"
            type="file"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @change="uploadAvatar($event)"
          />

          <span class="mb-2">{{ authUser.name }}</span>
        </v-card-title>
      </v-card>
    </v-col>

    <!-- user plan -->
    <v-col cols="12">
      <v-card
        class="user-plan"
        flat
      >
        <v-card-title class="flex-start align-start justify-space-between pb-0">
          <v-chip
            label
            color="primary"
            small
            class="v-chip-light-bg primary--text font-weight-semibold mb-3 me-3"
          >
            {{ 'Basic Plan' }}
          </v-chip>

          <div class="user-pricing">
            <span class="text-5xl font-weight-semibold primary--text">Free</span>
          </div>
        </v-card-title>

        <v-card-text>
          <v-list class="pa-0">
            <v-list-item
              v-for="benefit in standardPlan.benefits"
              :key="benefit"
              dense
              class="px-0 mb-n2"
            >
              <v-icon
                size="10"
                class="me-2"
              >
                {{ icons.mdiCheckboxBlankCircle }}
              </v-icon>
              <span>{{ benefit }}</span>
            </v-list-item>
          </v-list>

          <p class="d-flex font-weight-semibold text--primary mt-5 mb-2">
            <span>TweetShots</span>
            <v-spacer></v-spacer>
            <span>{{ limits.usedImages }} of {{ limits.maxImages }}</span>
          </p>
          <v-progress-linear
            :value="getLimitPercentage"
            height="8"
            rounded
          ></v-progress-linear>
          <p class="mt-2">
            Limits will reset in {{ limits.resetDays || 30 }} days
          </p>

          <v-btn
            color="primary"
            outlined
            block
          >
            Refer a friend to upgrade
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiCloudUploadOutline } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { getCurrentInstance, computed } from '@vue/composition-api'

import { mapActions } from 'vuex'
import useUsersList from '@/views/apps/user/user-list/useUsersList'

import apiClient from '@/services/'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },

  created() {
    this.fetchUsageLimits()
  },

  methods: {
    ...mapActions('social', ['fetchUsageLimits']),
  },

  setup(props, { root: { $store } }) {
    const authUser = computed(() => $store.state.auth.user)
    const vm = getCurrentInstance().proxy

    const limits = computed(() => $store.state.social.limits)

    const getLimitPercentage = computed(() => (limits.value.usedImages / limits.value.maxImages) * 100)

    const { resolveUserStatusVariant, resolveUserRoleVariant } = useUsersList()

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['Access all tools', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const uploadAvatar = $event => {
      const input = $event.target
      const file = input.files
      if (file && file[0]) {
        const data = new FormData()
        data.append('avatar', file[0])

        apiClient
          .changeAvatar(data)
          .then(res => {
            vm.$refs.refAvatarInput.value = null
            $store.dispatch('auth/setUserData', res.data.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    }

    return {
      authUser,
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      standardPlan,

      uploadAvatar,
      limits,
      getLimitPercentage,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  opacity: 0.7;
  justify-content: center;
  position: absolute;
  width: 100%;
  .icon {
    border-radius: 5px;
    padding: 4px;
    color: black;
    background: white;
  }
}
</style>
