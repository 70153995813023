<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="editBioDialog"
    max-width="650px"
    @click:outside="$emit('update:edit-bio-dialog',false)"
  >
    <v-card
      :loading="loading"
      class="user-edit-info pa-sm-10 pa-3"
    >
      <v-card-title class="justify-center text-h5">
        Edit User Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating user details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="updateForm"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-alert
            v-if="requestError"
            color="error"
            text
            dark
            class="my-4"
          >
            {{ requestError }}
          </v-alert>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="user.name"
                outlined
                :error-messages="errorMessages.name"
                :rules="[validators.required]"
                label="Full Name"
                placeholder="Full Name"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="user.email"
                outlined
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.emailValidator]"
                label="Email"
                placeholder="Email"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="user.country_code"
                :items="countriesFull"
                item-text="name"
                item-value="code"
                label="Country"
                outlined
                class="mb-6"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="user.website"
                outlined
                :error-messages="errorMessages.website"
                label="Website"
                placeholder="website"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="user.company_name"
                outlined
                label="Company Name"
                placeholder="Company Name"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="user.company_type"
                :items="data.companyTypes"
                label="Company Type"
                outlined
                class="mb-6"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="user.position"
                :items="data.positions"
                label="Position"
                outlined
                class="mb-6"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="user.department"
                :items="data.departments"
                label="Department"
                outlined
                class="mb-6"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:edit-bio-dialog',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'
import countriesFull from '@/@fake-db/data/other/countriesFull'
import userDB from '@/@fake-db/data/userDB'

import apiClient from '@/services'

export default {
  props: {
    editBioDialog: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit, root: { $store } }) {
    const userData = computed(() => $store.state.auth.user)
    const user = ref({
      name: userData.value.name,
      email: userData.value.email,
      website: userData.value.website,
      position: userData.value.position,
      company_type: userData.value.company_type,
      department: userData.value.department,
      company_name: userData.value.company_name,
      country_code: userData.value.country_code,
    })

    const loading = ref(false)
    const updateForm = ref(null)
    const errorMessages = ref([])
    const requestError = ref(null)

    // on form submit
    const onSubmit = () => {
      const isFormValid = updateForm.value.validate()

      if (!isFormValid) return
      requestError.value = null
      loading.value = true
      apiClient
        .updateUser({ ...user.value, onboarded: true })
        .then(response => {
          $store.dispatch('auth/setUserData', response.data.data)
          emit('update:edit-bio-dialog', false)
        })
        .catch(err => {
          requestError.value = err.response.data.error || err.response.data.message
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      user,
      data: userDB,

      loading,
      countries,
      countriesFull,

      validators: {
        required,
        emailValidator,
      },

      updateForm,
      requestError,
      errorMessages,
      onSubmit,
    }
  },
}
</script>
